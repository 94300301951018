import React from "react"

import SEO from "../components/seo"

const TrackRecord = () => (
    <>
        <SEO title="Présentation Agence Tactile" />
        <iframe
            className={"trackrecord-iframe fixed w-full h-screen inset-0 z-100 pt-24 md:p-24"}
            src="https://indd.adobe.com/embed/bd2accff-0fad-483c-9a8c-3c8d3688024c?startpage=1&allowFullscreen=false"
            width="650px"
            height="460px"
            frameBorder="0"
            allowFullScreen=""
        />
    </>
)

export default TrackRecord
